import { urlToList } from '@components/_utils/pathTools';
import { Breadcrumb } from 'antd';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { menuData } from 'src/components/PageLayout/menuData';

export default function BreadcrumbView({ extraCrumbs = [] }) {
  const router = useRouter();
  const [itemMap, setItemMap] = useState([]);
  const homeItem = { title: <Link href={'/dashboard'}>Home</Link> };

  useEffect(() => {
    function getItemMap(pathArr, menu = menuData, result = [homeItem]) {
      const [currentPath, ...restPath] = pathArr;
      const currentMenu = menu.find((ele) => currentPath === ele.path);
      if (currentMenu) {
        result.push({
          title: currentMenu.clickable ? currentMenu.label : currentMenu.name,
        });
        if (currentMenu.children && restPath.length) {
          getItemMap(restPath, currentMenu.children, result);
        }
      }
      return result;
    }
    setItemMap(getItemMap(urlToList(router.pathname)));
  }, []);
  return <Breadcrumb items={[...itemMap, ...extraCrumbs]} />;
}
