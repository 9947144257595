import { Box } from '@mui/material';
import Head from 'next/head';
import React from 'react';
import PageHeader from './PageHeader';

function DashboardLayout({ children, title, pageTitle, headerContent, extraCrumbs, loading }) {
  return (
    <>
      <Head>
        <title>{pageTitle ?? title} - AquaNow Administrator Site</title>
      </Head>
      <PageHeader
        title={title}
        content={headerContent}
        loading={loading}
        extraCrumbs={extraCrumbs}
      />
      <Box
        sx={{
          py: 3,
          px: 3,
          height: '100%',
          backgroundColor: 'rgba(243, 246, 249, 0.6)',
        }}
      >
        {children}
      </Box>
    </>
  );
}
export default DashboardLayout;
