import React from 'react';
import { Skeleton } from 'antd';
import classNames from 'classnames';
import styles from './index.module.scss';
import BreadcrumbView from './breadcrumb';

export default function PageHeader({
  title = '',
  content,
  extraContent,
  className,
  loading = false,
  wide = false,
  extraCrumbs,
}) {
  const clsString = classNames(styles.pageHeader, className);

  return (
    <div className={clsString}>
      <div className={wide ? styles.wide : ''}>
        <Skeleton
          loading={loading}
          title={false}
          active
          paragraph={{ rows: 3 }}
          avatar={{ size: 'large', shape: 'circle' }}
        >
          <BreadcrumbView extraCrumbs={extraCrumbs} />
          <div className={styles.detail}>
            <div className={styles.main}>
              <div className={styles.row}>
                <h1 className={styles.title}>{title}</h1>
              </div>
              <div className={styles.row}>
                {content && <div className={styles.content}>{content}</div>}
                {extraContent && <div className={styles.extraContent}>{extraContent}</div>}
              </div>
            </div>
          </div>
        </Skeleton>
      </div>
    </div>
  );
}
